/* Fonts */
@font-face {
  font-family: 'Pluto';
  src: url('/fonts/23D451_F_0.eot');
  src: url('/fonts/23D451_F_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/23D451_F_0.woff') format('woff'),
  url('/fonts/23D451_F_0.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pluto';
  src: url('/fonts/plutolight-webfont.eot');
  src: url('/fonts/plutolight-webfont.svg#PlutoLight') format('svg'),
  url('/fonts/plutolight-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/plutolight-webfont.woff') format('woff'),
  url('/fonts/plutolight-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pluto';
  src: url('/fonts/plutomedium-webfont.eot');
  src: url('/fonts/plutomedium-webfont.svg#PlutoMedium') format('svg'),
  url('/fonts/plutomedium-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/plutomedium-webfont.woff') format('woff'),
  url('/fonts/plutomedium-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pluto';
  src: url('/fonts/plutobold-webfont.eot');
  src: url('/fonts/plutobold-webfont.svg#PlutoBold') format('svg'),
  url('/fonts/plutobold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/plutobold-webfont.woff') format('woff'),
  url('/fonts/plutobold-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pluto';
  src: url('/fonts/plutoheavy-webfont.eot');
  src: url('/fonts/plutoheavy-webfont.svg#PlutoHeavy') format('svg'),
  url('/fonts/plutoheavy-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/plutoheavy-webfont.woff') format('woff'),
  url('/fonts/plutoheavy-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pluto';
  src: url('/fonts/plutoblack-webfont.eot');
  src: url('/fonts/plutoblack-webfont.svg#PlutoBlack') format('svg'),
  url('/fonts/plutoblack-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/plutoblack-webfont.woff') format('woff'),
  url('/fonts/plutoblack-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Typography */
body {
    font-family: "Pluto", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #000;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Pluto", sans-serif;
    font-style: normal;
    font-weight: 900;
    margin: 0;
}

h1 {
    @include font-size(44);
}

h2 {
    @include font-size(25);
}

h3 {
    @include font-size(22);
}

p, li {    
    font-weight: 300;
    margin-bottom: 1em;
}

p:last-of-type, li:last-of-type {
    margin-bottom: 0;
}