/* Colours */
$grey: #2d3031;
$light_grey: #171717;
$red: #EB5B56;
$pink: #F08175;
$yellow: #FEC524;

$retail_button: #FF5B37;
$retail_description: #F93B58;
$events_button: #EF86CE;
$events_description: #260B4A;
$creative_button: #CDD500;
$creative_description: #491D3F;