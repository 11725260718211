html {
    background-color: $grey;
}

body {
    overflow-x: hidden;
    background: $grey;
    color: #FFF;
}

img {
    max-width: 100%;
}

.wrapper {
    max-width: 1200px;
    padding: 0 35px;
    margin: 0 auto;
    &.narrow {
        max-width: 950px;
    }
    @media screen and (max-width: 600px) {
        padding: 0 20px;    
    }
}

header.header {
    padding: 35px 0;
    .wrapper {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 600px) {
            display: block;    
        }
    }
    img {
        width: 250px;
    }
    ul {
        display: flex;
        justify-content: flex-end;
        width: 210px;
        margin-top: 30px;
        li {
            margin: 0;
        }
        a {
            font-weight: 400;
            color: #FFF;
            text-decoration: none;
            transition: color .2s ease;
            @include font-size(11);
            &:hover {
                color: $yellow;
            }
            span {
                display: inline-block;
                transition: transform .2s linear;
                &.active {
                    transform: rotate(45deg);
                }
            }
        }
    }
}

nav {
    display: none;
    ul {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding-bottom: 60px;
        max-width: 750px;
        li {
            margin: 0;
            color: $yellow;
            font-weight: 400;
        }
        a {
            text-decoration: none;
            color: #FFF;
            @include font-size(11);
            &:hover {
                color: #FEC524;
            }
        }
    }
}

.policy {
    h1, h2 {
        text-transform: uppercase;
    }
    p {
        line-height: 140%;
    }
    a {
        color: $yellow;
    }
}

footer {
    margin: 25px 0 50px;
    .wrapper {
        display: flex;
        justify-content: space-between;
        .footer__logo {
            width: 25%;
            img {
                width: 200px;
            }
            p {
                @include font-size(9);
                strong {
                    font-weight: 500;
                }
                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }
        .footer__main {
            width: 65%;
            .footer__quote {
                display: flex;
                justify-content: space-between;
                padding-top: 20px;
                p {
                    margin: 0;
                    font-weight: 400;
                    @include font-size(12);
                }
                ul {
                    display: flex;
                    justify-content: space-between;
                    width: 80px;
                    a {
                        color: #FFF;
                    }
                }
            }
            .footer__links {
                .footer_second_list {
                    justify-content: flex-start !important;
                    .break {
                        margin: 0 20px;
                    }
                }
                ul {
                    display: flex;
                    justify-content: space-between;
                    margin: 25px auto 0;
                    li {
                        margin: 0;
                        color: $yellow;
                    }
                    a {
                        text-decoration: none;
                        color: #FFF;
                        @include font-size(9);
                    }
                }
            }
        }
        @media screen and (max-width: 600px) {
            display: block;
            text-align: center;
            .footer__logo, .footer__main {
                width: auto;
                .footer__quote {
                    display: block;
                    ul {
                        margin: 20px auto 0;
                    }
                }
                .footer__links {
                    ul {
                        flex-wrap: wrap;
                        li {
                            flex-basis: 50%;
                            &:nth-child(even) {
                                display: none;
                            }
                            a {                        
                                @include font-size(8);
                            }
                        }
                    }
                }
            }            
        }
    }
    .modal-header {
        border-bottom: 1px solid #c9c9c9;
        color: #FEC524;
         .btn-close {
            margin-right: 0px;
         }
    }
    .modal-content {
        color: #505050;
    }
    .modal-body {
        p {
            display: flex;
            justify-content: center;
        }
        .wpcf7-text {
            margin-top: 3px;
            font-weight: 100;
            border: 1px solid #7F796A;
            height: 30px;
            padding: 5px;
            border-radius: 5px;
        }
        .wpcf7-textarea {
            margin-top: 3px;
            font-weight: 100;
            border: 1px solid #7F796A;
            width: 100%;
            padding: 5px;
            border-radius: 5px;
        }
        .wpcf7-submit {
            display: inline-block;
            text-align: center;
            padding: 10px 15px;
            border-radius: 5px;
            background-color: #FEC524;
            color: #fff;
            font-weight: 400;
            &:hover {
                color: #7F796A;
            }
        }
        .wpcf7-spinner {
            position: absolute;
            bottom: 60px;
            right: 130px;
            width: 21px;
            height: 21px;
        }
        label {
            font-weight: 400;
            width: 100%;
            display: block;
            input {
                width: 100%;
            }
        }
    }
    .recaptcha {
        display: block;
        color: #7F796A;
        border-top: 1px solid #7F796A;
        margin-top: 20px;
        padding-top: 5px;
        font-size: 0.5rem;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        a {
            color: #b18a1e;
            text-decoration: none;
        }
    }
}

.grecaptcha-badge { 
    visibility: hidden;
}

a {
    outline: none !important;
}
