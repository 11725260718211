body {
    &.homepage {
        h1 {
            height: 130px;
        }
    }
    h1 {
        margin: 25px 0;
        text-transform: uppercase;
        letter-spacing: -3px;
        font-weight: 700;
        @include font-size(72);
        @media screen and (max-width: 600px) {
            @include font-size(40);    
        }
    }
    main > .wrapper > p {
        @include font-size(14);
        &.intro {
            margin-bottom: 40px;
            line-height: 120%;
            @include font-size(26);
            @media screen and (max-width: 600px) {
                @include font-size(16);    
            }
        }
    }
    .home__blocks {   
        padding-top: 80px;
        .grid {
            display: grid;
            grid-gap: 20px;
            grid-template-rows: 370px;
            &.one-two-thirds {
                grid-template-columns: 1fr 2fr;
            }
            &.two-one-thirds {
                grid-template-columns: 2fr 1fr;
            }
            &.triple {
                grid-template-columns: .9fr 1.2fr .9fr;
            }
            &.halves {
                grid-template-columns: 1fr 1fr;
            }
            &.blog {
                grid-template-rows: 390px 390px;
                grid-template-columns: 1fr 1fr;
            }
            .block {
                display: block;
                margin-bottom: 20px;   
                transition: all .2s ease;
                will-change: transform, opacity;
                text-decoration: none;
                -ms-grid-column-span: 1;
                
                figure {
                    height: 100%;
                    .block-3-image {
                        background-position: unset !important;
                    }
                    .block__image {
                        position: relative;
                        background-size: cover;
                        background-position: center center;
                        background-color: #AAA;
                        height: 100%;
                        .snapshot {
                            max-width: 320px;
                        }
                        & > h2, & > h3 {
                            position: absolute;
                            left: 25px;
                            right: 25px;
                            color: #FFF;
                            font-weight: 400;
                            line-height: 110%;
                        }
                        & > h2 {                
                            top: 80px;                
                            max-width: 255px;                
                            @include font-size(16);
                        }
                        & > h3 {
                            top: 150px;
                            @include font-size(11);
                        }
                        .block__button {
                            position: absolute;
                            bottom: 125px;
                            left: 25px;
                            display: block;
                            padding: 10px 18px;
                            border: 2px solid #FFF;
                            background: transparent;
                            color: #FFF;
                            text-transform: uppercase;
                            font-weight: 400;
                            letter-spacing: 1px;
                            opacity: 0;
                            will-change: opacity;
                            transition: opacity .2s ease;
                            @include font-size(7);
                        }
                        .block__issue {
                            position: absolute;
                            top: 27px;
                            right: 25px;
                            display: block;
                            padding: 5px 7px;
                            background: #FFF;
                            font-weight: 400;
                            text-transform: uppercase;
                            color: $grey;
                            @include font-size(7);
                        }
                        .block__logo {
                            position: absolute;
                            top: 0;
                            left: 25px;
                            width: 45px;
                            padding: 15px 10px;
                            background: #FFF;
                        }
                    }
                    figcaption {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        padding: 20px 25px;
                        opacity: 0;
                        background: $light_grey;
                        line-height: 110%;
                        color: #FFF;
                        transition: all .2s ease;
                        @include font-size(10);
                    }
                }
                &:hover {
                    box-shadow: 1px 1px 8px #000;
                    transform: scale(1.031) translateZ(0);
                    opacity: 1 !important;
                    figure {
                        .block__image {         
                            .block__button {
                                opacity: 1;
                            }
                        }
                        figcaption {
                            opacity: 1;
                        }           
                    }
                }
                /*&.feed:hover {
                    opacity: 1;
                }*/
                &.inactive {
                    opacity: .5;
                }
                &.block-1 .block__button, &.block-5 .block__button {
                    background: #1D1D1B !important;
                }
                &.block-2 {
                    grid-column-start: 2;
                    .block__button {
                        background: #260B4A !important;
                        border-color: #EF86CE !important;
                    }
                }
                &.block-3 .block__button, &.block-9 .block__button {
                    background: #260B4A !important;
                    border-color: #EF86CE !important;
                }
                &.block-4 {
                    grid-column-start: 2;
                    .block__button {
                        background: #37122D !important;
                        border-color: #CDD500 !important;
                    }
                }
                &.block-6 , &.block-8 {
                    grid-column-start: 2;
                    .block__button {
                        background: #000 !important;
                        border-color: #CF132B !important;
                    }
                }
                &.block-7 .block__button {
                    background: #FB9172 !important;
                }
                &.block-10 {
                    grid-column-start: 2;
                    .block__button {
                        background: #EC7474 !important;
                    }
                }
                &.block-11 {
                    grid-column-start: 3;
                    .block__button {
                        background: #F5A683 !important;
                    }
                }
                &.block-12, &.block-14 {
                    grid-column-start: 2;
                }
                &.block-13, &.block-15 {
                    grid-column-start: 3;
                }
                &.block-14, &.block-15 {
                    grid-row-start: 2;
                }
            }
            &.blog {
                margin-bottom: 50px;
                .block {
                    margin-bottom: 0;            
                    &.feed {
                        grid-row-start: 1;
                        grid-row-end: 3;
                        -ms-grid-row-span: 2;
                        figure {
                            .block__image {
                                background-color: rgba(241, 192, 180, 1);
                                &.yellowBackground {
                                    background: rgba(244, 200, 68, 1);

                                }
                                .content {
                                    position: absolute;
                                    top: 155px;
                                    right: 25px;
                                    bottom: 40px;
                                    left: 25px;
                                    article {
                                        border-bottom: 1px solid $red;
                                        margin-bottom: 25px;
                                        min-height: 145px;

                                        &.goldBorder {
                                            border-bottom: 1px solid #E4A400;
                                        }

                                        a {
                                            color: #FFF;
                                            text-decoration: none;
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                        h3 {
                                            font-weight: 400;
                                            @include font-size(12);
                                            min-height: 42px;
                                        }
                                        p {
                                            line-height: 150%;
                                            margin: 15px 0 25px;
                                            @include font-size(9);
                                        }
                                        &:last-child {
                                            border-bottom: none;
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                                & > a {
                                    display: none; // hidden as only have 3 articles
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    width: 45px;
                                    height: 45px;
                                    padding: 10px;
                                    background: $red;
                                    text-align: center;
                                    img {
                                        height: 24px;
                                    }
                                    &.prev {
                                        right: 47px;
                                        transform: scaleX(-1);
                                    }
                                }
                            }
                        }
                    }
                    &.bwd-retail-website figure .block__image {
                        background-position: center right;
                    }
                }
            }
            @media screen and (max-width: 800px) {
                display: block;
                .block {
                    figure .block__image {
                        height: 350px;
                    }                    
                }
                &.blog .block {
                    figure {
                        margin-bottom: 20px;
                    }
                    &.feed {
                        figure .block__image {
                            height: 600px;

                        }
                    }
                }
            }
            @media screen and (max-width: 600px) {
                .block {
                    figure {
                        .block__image {
                            height: auto;
                            min-height: 265px;
                            padding: 80px 25px 0;
                            & > h2, & > h3, .block__button {
                                position: static;
                            }
                            & > h3 {
                                margin-top: 15px; 
                            }
                            .block__button {
                                opacity: 1;
                            }
                            .block__button {
                                display: inline-block;
                                margin: 25px 0 35px;
                            }
                        }
                        figcaption {
                            position: static;
                            opacity: 1;
                        }
                    }
                }
                &.blog .block.feed figure .block__image {
                    height: auto;
                    .content {
                        position: static;
                        padding: 25px 0 50px;
                    }
                }
            }
        }
    }
}